<template>
  <colorTextBtn @click="onCopy">
    复制原型
  </colorTextBtn>
</template>

<script>
import { getProtoGroupDetail, createProtoGroup } from '@/api/product/protoGroupApi'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    async onCopy() {
      const confirm = await awaitFormResolve(
        this.$confirm('确认需要复制原型吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      )
      if (!confirm) return
      const res = await this.getDetail()
      if (!res) return
      const prototypeIdList = $GET(res, 'prototypeIdList', [])
      const { name } = this.data
      const copyName = `${name} 副本`
      const res1 = await awaitResolveDetailLoading(createProtoGroup({
        name: copyName,
        prototypeIdList
      }))
      if (!res1) return
      this.$message.success('操作成功')
      this.$emit('successHandler')
    },

    async getDetail() {
      const { id } = this.data
      return awaitResolveDetailLoading(getProtoGroupDetail({ id }))
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
